<template>
  <div>
    <!-- Events of Selection -->
    <div class="card sticky-top rounded-0 border shadow" v-if="selected.length">
      <div class="card-body text-center">
        <!-- Clear -->
        <button
          class="btn btn-sm btn-outline-secondary"
          v-on:click="selected = []"
        >
          <i class="fas fa-redo"></i> <span class="ml-2">Seçimi Temizle</span>
        </button>
        <!-- ./Clear -->

        <!-- Delete -->
        <button
          class="btn btn-sm btn-outline-danger ml-3"
          v-on:click="onDelete"
        >
          <!-- Remove Button -->
          <template v-if="!removeLoading">
            <i class="fas fa-trash"></i>
            <span class="ml-2"
              >Seçili Mesajları Sil ({{ selected.length }})</span
            >
          </template>

          <i class="fas fa-spinner fa-spin" v-else></i>
          <!-- ./Remove Button -->
        </button>
        <!-- ./Delete -->
      </div>
    </div>
    <!-- ./Events of Selection -->

    <!-- Notification -->
    <div class="card card-body m-2 p-2 alert-warning">
      <p class="message-balloon-text text-center">
        Sipariş notu ekleyebilirsiniz. Bu alan sadece
        <b>Yönetim Panelinde</b>
        görünür.
      </p>
    </div>
    <!-- ./Notification -->

    <div
      v-for="(msg, index) in messages"
      :key="index"
      @click="onSelect(msg)"
      v-bind:class="[
        'px-3 py-2',
        isSelected(msg.id) ? 'message-balloon--selected' : '',
      ]"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <b>{{ msg.user.full_name }}</b>
        </div>
        <div class="col-auto pl-0">
          <div class="text-muted font-12">
            {{ msg.created_at | dateFormat }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div
          v-bind:class="[
            'col-auto message-balloon',
            currentUserId == msg.user.id ? 'message-balloon-owner' : '',
          ]"
        >
          <p class="message-balloon-text">
            {{ msg.note }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Messages",
  props: {
    messages: {
      default: () => [],
    },
    currentUserId: {
      default: null,
    },
  },
  model: {
    prop: "messages",
  },
  data() {
    return {
      selected: [],
      removeLoading: false,
    };
  },
  methods: {
    ...mapActions("order", ["deleteOrderNote"]),
    isLastElement(index) {
      return this.messages.length - 1 == index;
    },
    isSelected(id) {
      return this.selected.find((msgId) => msgId == id);
    },
    onSelect(msg) {
      if (msg.user.id !== this.currentUserId) return;

      const index = this.selected.findIndex((item) => item == msg.id);

      if (index == -1) {
        this.selected.push(msg.id);
      } else {
        this.selected.splice(index, 1);
      }
    },
    onDelete() {
      if (this.selected && this.selected.length) {
        this.removeLoading = true;
        this.deleteOrderNote({
          selected: this.selected,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              // Seçili Mesajları Mesaj dizisinden çıkar
              this.selected.forEach((id) => {
                const messageIndex = this.messages.findIndex(
                  (msg) => msg.id == id
                );
                if (messageIndex >= 0) {
                  this.messages.splice(messageIndex, 1);
                }
              });

              // Seçili Mesajları Temizle
              this.selected = [];
              this.$toast.success("Sipariş Notları başarıyla silindi");
            }
          },
          onFinish: () => {
            this.removeLoading = false;
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.message-balloon {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgba($color: #dcdcdd, $alpha: 0.7);
  border: 1px solid rgb(221, 221, 221);
  &-text {
    margin: 0;
    padding: 0;
  }
  &-owner {
    background-color: rgba($color: #6bcfbf, $alpha: 0.6);
    border: 1px solid #8be7d8;
  }
  &--selected {
    background-color: #6bb3cf49;
  }
}
</style>