<template>
  <WeModal v-bind:title="getOrderTitle" v-on:close="$emit('close')" style="">
    <div
      slot="body"
      class="custom-scrollbar"
      style="max-width: 100%; min-height: 250px"
    >
      <div v-if="order">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <!-- Order Detail -->
          <li class="nav-item col-12 col-md-auto p-0" role="presentation">
            <a
              href="#order-detail"
              class="nav-link active font-14"
              id="order-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="order-detail"
              aria-selected="true"
            >
              <span class="ml-1">Sipariş Detayı</span>
            </a>
          </li>
          <!-- ./Order Detail -->

          <!-- Order Detail -->
          <li class="nav-item col-12 col-md-auto p-0" role="presentation">
            <a
              href="#invoice-detail"
              class="nav-link mt-2 mt-md-0 ml-md-2 font-14"
              id="invoice-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="invoice-detail"
              aria-selected="true"
            >
              <span class="ml-1">Fatura & Teslimat</span>
            </a>
          </li>
          <!-- ./Order Detail -->

          <!-- Cargo Info -->
          <li
            class="nav-item col-12 col-md-auto p-0"
            role="presentation"
            v-if="showCargoTab"
          >
            <a
              href="#cargo-detail"
              class="nav-link mt-2 mt-md-0 ml-md-2 font-14"
              id="cargo-detail-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="cargo-detail"
              aria-selected="true"
            >
              <span class="ml-1">Kargo Bilgisi</span>
            </a>
          </li>
          <!-- ./Cargo Info -->

          <!-- ERP Info -->
          <li
            class="nav-item nav-item col-12 col-md-auto p-0"
            role="presentation"
            v-if="
              erpConfig &&
              order.erp &&
              order.erp.message &&
              order.order_type !== 'İptal Edildi'
            "
          >
            <a
              href="#erp-info"
              class="nav-link mt-2 mt-md-0 ml-md-2 font-14"
              id="erp-info-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="erp-info"
              aria-selected="true"
            >
              <span class="ml-1">ERP</span>
            </a>
          </li>
          <!-- ERP Info -->

          <!-- Bank Result -->
          <li
            class="nav-item nav-item col-12 col-md-auto p-0"
            role="presentation"
            v-if="order.bank_result.data"
          >
            <a
              href="#bank-result"
              class="nav-link mt-2 mt-md-0 ml-md-2 font-14"
              id="bank-result-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="bank-result"
              aria-selected="true"
            >
              <span class="ml-1">Banka Sonuç Bilgisi</span>
            </a>
          </li>
          <!-- Bank Result -->

          <li
            class="nav-item nav-item col-12 col-md-auto p-0"
            role="presentation"
          >
            <a
              href="#order-note"
              class="nav-link mt-2 mt-md-0 ml-md-2 font-14"
              id="order-note-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="order-note"
              aria-selected="true"
            >
              <span class="ml-1">Notlar</span>
            </a>
          </li>
          <li class="nav-item mt-3 mt-md-0 ml-md-auto" role="presentation">
            <span
              class="btn btn-outline-secondary"
              v-print="printConfig"
              ref="printButton"
            >
              <i class="fas fa-print"></i> Yazdır
            </span>
          </li>
        </ul>
        <div
          class="tab-content custom-scrollbar pr-3"
          style="max-height: 400px; overflow-x: auto"
        >
          <!-- Order Detail -->
          <div
            class="tab-pane fade active show"
            id="order-detail"
            role="tabpanel"
            aria-labelledby="order-detail-tab"
          >
            <DetailTab v-bind:order="order" />
          </div>
          <!-- ./Order Detail -->

          <!-- Address -->
          <div
            class="tab-pane fade"
            id="invoice-detail"
            role="tabpanel"
            aria-labelledby="invoice-detail-tab"
          >
            <InvoiceTab v-bind:order="order" />
          </div>
          <!-- ./Address -->

          <!-- Cargo Info -->
          <div
            class="tab-pane fade"
            id="cargo-detail"
            role="tabpanel"
            aria-labelledby="cargo-detail-tab"
          >
            <div class="row">
              <div
                :class="
                  (order.cargoDetail.cargoStatus.length ? 'col-6' : 'col-12')
                "
              >
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="col-12 mb-3">
                      <WeInput
                        class="mb-3 mb-lg-0"
                        v-model="order.cargoDetail.package_quantity"
                        v-bind:filter-number="true"
                        label="Koli Miktarı"
                        name="package-quantity"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="col-12 mb-3">
                      <WeInput
                        class="mb-3 mb-lg-0"
                        v-model="order.cargoDetail.package_desi"
                        v-bind:filter-number="true"
                        label="Toplam Desi"
                        name="package-desi"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label for="cargo-id" class="custom-label"
                    >Kargo Firması</label
                  >
                  <v-select
                    id="cargo-id"
                    v-bind:options="getCargoCompanyNames"
                    v-bind:clearable="false"
                    label="name"
                    v-bind:value="
                      order.cargoDetail.cargo &&
                      order.cargoDetail.cargo.cargo_company
                        ? order.cargoDetail.cargo.cargo_company.name
                        : order.cargoDetail.cargo
                    "
                    v-on:input="order.cargoDetail.cargo = $event"
                    v-bind:selectable="(option) => option.selectable"
                  >
                    <template #option="{ name, selectable }">
                      {{ name }}
                      <i
                        v-if="!selectable"
                        class="fas fa-info-circle ml-2"
                        v-tooltip="'Teslimat Bölgesi Dışı'"
                      ></i>
                    </template>
                  </v-select>
                </div>
                <div
                  class="col-12 mb-3"
                  v-if="
                    order.cargoDetail &&
                    order.cargoDetail.cargo &&
                    order.cargoDetail.cargo.is_insurance
                  "
                >
                  <WeInput
                    class="mb-3 mb-lg-0"
                    v-model="order.cargoDetail.product_type"
                    label="Ürün Tipi"
                    name="product-type"
                  />
                </div>
                <div class="col-12 mb-3">
                  <label for="cargo-id" class="custom-label"
                    >Kargo Kayıt Numarası</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="cargo-id"
                    v-model="cargoBarcodeNumber"
                    disabled
                  />
                </div>
                <div
                  class="col-12 mb-3"
                  v-if="order.cargoDetail.cargo_barcode_number"
                >
                  <div class="row">
                    <div class="col-12 col-lg-7">
                      <label for="cargo-id" class="custom-label"
                        >Kargo Takip Numarası</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="cargo-id"
                        v-model="cargoTrackingNumber"
                      />
                    </div>

                    <div class="col-12 col-lg-5">
                      <label for="cargo-id" class="custom-label"></label>
                      <div class="text-right">
                        <span
                          class="btn btn-info"
                          v-on:click="onSubmitGetTrackingNumber"
                          :disabled="isSubmit"
                          ><i
                            :class="
                              isGetTrackingSubmit
                                ? 'fas fa-spinner fa-spin'
                                : 'fas fa-sync'
                            "
                          ></i>
                          Takip Kodunu Çek</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12"
                  v-if="
                    order.cargoDetail &&
                    order.cargoDetail.cargo
                  "
                >
                  <div class="row justify-content-between mb-3">
                    <!-- ıdır -->
                    <div class="col-auto">
                      <span
                        class="btn btn-sm btn-success"
                        v-on:click="onSubmitCargoDetail"
                        :disabled="isSubmit"
                        ><i
                          :class="
                            isSetCargoSubmit
                              ? 'fas fa-spinner fa-spin mr-1'
                              : 'fas fa-sync mr-1'
                          "
                        ></i>
                        {{ getBarcodeText }}</span
                      >
                    </div>
                    <!-- ./Barkod Yazdır -->
                    <div class="col-auto" v-if="order.cargoDetail.barcode_url">
                      <PrintBarcodeButton
                        v-bind:order-id="order.id"
                        v-bind:cargo-code="getCargoCompanyCode"
                        v-bind:barcode-url="order.cargoDetail.barcode_url"
                        v-bind:package-quantity="
                          order.cargoDetail.package_quantity
                        "
                      />
                    </div>
                    <!-- <div class="col-12 mt-2">
                    <button
                      class="btn btn-sm btn-info"
                      v-on:click.prevent="$emit('on-update-info', order)"
                    >
                      Kargo Bilgisi Güncelle
                    </button>
                  </div> -->
                  </div>
                </div>
                <div
                  class="text-left ml-3"
                  v-if="
                    order.cargoDetail.cargo &&
                    !order.cargoDetail.cargo.is_integration
                  "
                >
                  <span class="btn btn-success" v-on:click="onSubmitCargoDetail"
                    ><i class="fas fa-sync"></i> Güncelle</span
                  >
                </div>
              </div>

              <div
                class="p-0 col-6"
                v-if="order.cargoDetail.cargoStatus.length > 0"
              >
                <div class="timeline custom-scrollbar">
                  <div class="title">
                    <h1><i class="fa fa-truck"></i> Kargo Durumu</h1>
                  </div>
                  <div class="events">
                    <div
                      class="event"
                      v-for="(item, index) in order.cargoDetail.cargoStatus"
                      v-bind:key="index"
                      v-bind:index="index"
                    >
                      <div :class="'knob status-' + item.status"></div>
                      <div class="description">
                        <p>
                          <span>{{ dateToString(item.created_at) }}</span>
                        </p>
                        <p>{{ item.message }}</p>
                      </div>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Cargo Info -->

          <!-- ERP -->
          <div
            class="tab-pane fade"
            id="erp-info"
            role="tabpanel"
            aria-labelledby="erp-info-tab"
          >
            <Erp v-bind:erp="order.erp" />
          </div>
          <!-- ERP -->
          <!-- Bank Result -->
          <div
            class="tab-pane fade"
            id="bank-result"
            role="tabpanel"
            aria-labelledby="bank-result-tab"
          >
            <BankResult v-bind:result="order.bank_result" />
          </div>
          <!-- Bank Result -->

          <!-- Order Notes -->
          <div
            class="tab-pane fade"
            id="order-note"
            role="tabpanel"
            aria-labelledby="order-note-tab"
          >
            <Notes v-model="order.notes" v-bind:order-id="order.id" />
          </div>
          <!-- ./Order Notes -->
        </div>
        <!-- Print All -->
        <div v-bind:id="printConfig.id" class="print">
          <DetailTab
            v-bind:order="order"
            class="mb-3"
            v-bind:site-logo="siteLogo"
          />
          <InvoiceTab v-bind:order="order" />
        </div>
        <!-- Print All -->
      </div>
      <!-- Order Detail -->

      <WeLoading v-else />
    </div>
  </WeModal>
</template>
<script>
import { mapState } from "vuex";
import DetailTab from "./views/DetailTab/Index";
import InvoiceTab from "./views/InvoiceTab/Index";
import Erp from "./views/Erp/Index";
import BankResult from "./views/BankResult/Index";
import Notes from "./views/Notes/Index.vue";

const PrintBarcodeButton = () =>
  import("../../components/PrintBarcodeButton/Index.vue");

export default {
  name: "OrderDetail",
  data() {
    return {
      printConfig: {
        id: "printOrder",
        popTitle: "Sipariş Detayı",
      },
      isSubmit: false,
      isSetCargoSubmit: false,
      isGetTrackingSubmit: false,
    };
  },
  components: {
    DetailTab,
    InvoiceTab,
    Erp,
    BankResult,
    PrintBarcodeButton,
    Notes,
  },
  props: {
    order: {
      default: () => {},
    },
    showModal: {
      default: false,
    },
    erpConfig: {
      default: false,
    },
  },
  methods: {
    onSubmitCargoDetail() {
      this.isSetCargoSubmit = true;
      this.onSubmit();
      this.$emit("on-update-cargo");
    },
    onSubmitGetTrackingNumber() {
      this.isGetTrackingSubmit = true;
      this.onSubmit();
      this.$emit("on-get-tracking-number");
    },
    dateToString(date) {
      return helper.dateToString(date);
    },
    onSubmit() {
      this.isSubmit = true;
    },
    onReturnSubmit(type) {
      switch (type) {
        case "update-cargo":
          this.isSetCargoSubmit = false;
          break;
        case "get-tracking-number":
          this.isGetTrackingSubmit = false;
          break;
      }
      this.isSubmit = false;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
      cargo: (state) => state.shared.cargoCompanies,
    }),
    getCargoCompanyNames() {
      let result = [];

      this.cargo.firmList.forEach((cargoFirm) => {
        const cargoData = {
          id: cargoFirm?.id,
          name: cargoFirm?.cargo_company?.name,
          cargo_company: cargoFirm.cargo_company,
          is_active: cargoFirm.is_active,
          is_insurance: cargoFirm.is_insurance,
          is_integration: cargoFirm.is_integration,
          selectable:
            this.order &&
            this.order.available_cargo_ids &&
            this.order.available_cargo_ids.includes(cargoFirm.id),
        };

        if (cargoData && cargoData.id && cargoData.name) {
          result.push(cargoData);
        }
      });

      return result;
    },
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    cargoTrackingNumber: {
      get() {
        return this.order.cargoDetail.cargo_tracking_number || null;
      },
      set(value) {
        this.order.cargoDetail.cargo_tracking_number = value;
      },
    },
    cargoBarcodeNumber: {
      get() {
        return this.order.cargoDetail.cargo_barcode_number || null;
      },
      set(value) {
        this.order.cargoDetail.cargo_barcode_number = value;
      },
    },
    getBarcodeText() {
      return "Güncelle";
      // return this.order.cargoDetail.barcode_url
      //   ? "Barkod Yenile"
      //   : "Barkod Oluştur";
    },
    showCargoTab() {
      return (
        this.order.cargoDetail &&
        (this.order.cargoDetail.cargo ||
          this.order.cargoDetail.cargo_tracking_number ||
          this.order.cargoDetail.barcode_url)
      );
    },
    getCargoCompanyCode() {
      let result = this.order.cargo_company_code;

      if (
        !result &&
        this.order.cargoDetail &&
        this.order.cargoDetail.cargo &&
        this.order.cargoDetail.cargo.cargo_company
      )
        result = this.order.cargoDetail.cargo.cargo_company.code;

      return result;
    },
    getOrderTitle() {
      const order = this.order;
      if (order) {
        const orderNo = order.order_no;
        const shipmentCustomer = order.customer.shipment;

        let customerName = "";
        if (
          shipmentCustomer &&
          shipmentCustomer.company &&
          shipmentCustomer.company.length
        ) {
          customerName = shipmentCustomer.company;
        } else {
          customerName =
            shipmentCustomer.first_name + " " + shipmentCustomer.last_name;
        }

        return `${orderNo} - ${customerName}`;
      }
    },
  },
};
</script>
<style lang="scss">
@media print {
  .print {
    .card {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border: none !important;
      .card-header {
        padding-left: 0 !important;
        .p-0 {
          display: none;
        }
      }
      .card-body {
        padding: 0 !important;
      }
    }
    .table {
      th,
      td {
        font-size: 16px !important;
        padding: 3px !important;
      }
    }
    .products-table {
      th {
        text-align: center !important;
      }
    }
  }
}

body {
  font-family: system-ui;
}

.timeline {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  gap: 32px;
  max-height: 250px;
}

.title > h2,
.title > h1 {
  margin: 0;
}
.timeline .title > h1 {
  font-size: 18px;
}

.title > h2 {
  opacity: 50%;
}

.events {
  position: relative;

  display: grid;
  grid-template-columns: 10px 1fr;
  row-gap: 10px;

  width: fit-content;
}

.event {
  display: grid;
  grid-template-columns: 10px 1fr;
  column-gap: 10px;
  grid-column: 1 / 3;
}

.date > h2,
.description > p {
  margin: 0;
}

.knob {
  grid-column: 1 / 2;
  align-self: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  z-index: 1;

  background: #4c4c4c;
  border-radius: 50%;
}

.date,
.description {
  grid-column: 2 / 3;
  align-self: center;
}

.description span {
  opacity: 0.5;
}

.line {
  position: absolute;

  grid-column: 1 / 2;
  justify-self: center;

  height: 100%;
  width: 2px;
  z-index: 0;

  background-color: rgba(0, 0, 0, 0.1);
}
.event .status-2 {
  background-color: rgba(235, 198, 34);
}

.event .status-3 {
  background-color: rgb(76, 195, 241);
}

.event .status-5 {
  background-color: rgba(23, 230, 126);
}

.event .status-6 {
  background-color: rgb(250, 10, 30);
}
</style>
