<template>
  <div class="note-wrapper">
    <!-- Background -->
    <img src="/img/background/commerce.png" class="note-background" />
    <!-- ./Background -->

    <div class="note-content-wrapper">
      <div class="note-content custom-scrollbar">
        <!-- Messages -->
        <Messages v-model="notes" v-bind:current-user-id="currentUserId" />
        <!-- ./Messages -->
      </div>

      <!-- Send Message -->
      <div class="note-sender">
        <WeInput
          class="mb-0"
          name="send-message"
          placeholder="Sipariş Notu Girin"
          v-model="message"
          v-on:keyup-enter="onSend"
        >
          <template #append>
            <button class="btn btn-primary" v-on:click="onSend">
              <template v-if="!loading">
                {{ $t("send") }} <i class="fas fa-paper-plane ml-2"></i>
              </template>
              <i class="fas fa-spinner fa-spin" v-else></i>
            </button>
          </template>
        </WeInput>
      </div>
      <!-- ./Send Message -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Messages from "./components/Messages/Index.vue";
export default {
  name: "Notes",
  components: {
    Messages,
  },
  props: {
    notes: {
      default: () => [],
    },
    orderId: {
      default: null,
    },
  },
  model: {
    prop: "notes",
  },
  data() {
    return {
      message: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions("order", ["createOrderNote"]),
    onSend() {
      const userData = {};
      userData.id = this.currentUserId;
      userData.full_name = this.session.userFullName;

      if (this.message) {
        this.loading = true;

        this.createOrderNote({
          order_id: this.orderId,
          note: this.message,
          user: userData,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.notes.push({
                id: result.data.id,
                note: this.message,
                user: userData,
                created_at: new Date().getTime(),
              });
              this.$toast.success("Sipariş Notu Eklendi");
            }
          },
          onError: () => {
            this.$toast.error("Sipariş Notu Eklenemedi");
          },
          onFinish: () => {
            this.loading = false;
            this.message = null;
          },
        });
      } else {
        this.$toast.error("Lütfen Mesaj Girin");
      }
    },
  },
  computed: {
    ...mapState(["session"]),
    currentUserId() {
      return this.session.userId;
    },
  },
};
</script>
<style lang="scss">
.note-wrapper {
  position: relative;
  overflow: hidden;
}
.note-content-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 400px;
}
.note-background {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}
.note-content {
  position: relative;
  height: 471px;
  overflow: auto;
  flex: auto;
}
.note-sender {
  width: 100%;
}
</style>